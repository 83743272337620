import './bootstrap';
import 'clipboard/dist/clipboard.js';
import '~bootstrap/dist/js/bootstrap.bundle.js';
import {createApp, defineAsyncComponent} from 'vue/dist/vue.esm-bundler';

import DetermineConsumption from './tariff-calculator/DetermineConsumption.vue';
import SecondContractPartner from './tariff-calculator/SecondContractPartner.vue';
import InvoiceAddressDiffers from './tariff-calculator/InvoiceAddressDiffers.vue';
import SelectTermination from './tariff-calculator/SelectTermination.vue';
import BankAccountNameDiffers from './tariff-calculator/BankAccountNameDiffers.vue';
import CompareConditions from './tariff-calculator/CompareConditions.vue';
import ClipboardJS from "clipboard";

const app = createApp({});

app.component('determine-consumption', DetermineConsumption);
app.component('second-contract-partner', SecondContractPartner);
app.component('invoice-address-differs', InvoiceAddressDiffers);
app.component('select-termination', SelectTermination);
app.component('select-termination', SelectTermination);
app.component('bank-account-name-differs', BankAccountNameDiffers);
app.component('compare-conditions', CompareConditions);

app.mount('#tariff-calculator-app');
app.mount('#intern-app');

new ClipboardJS('.vs-clipboard-btn');

import * as bootstrap from '../../node_modules/bootstrap/dist/js/bootstrap.bundle'
window.bootstrap = bootstrap
const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))